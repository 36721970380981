.how-to-start {
    --grid-template: repeat(3, minmax(0, 1fr));
    --outer-gap: 20px;

    padding-block: var(--section-padding-block);
    background-color: var(--secondary-main);
}

.how-to-start__title {
    color: var(--light-main);
}

.how-to-start__subtitle {
    max-width: 600px;
    margin-block-end: 48px;
    color: var(--light-main);
}

.how-to-start__steps {
    display: grid;
    grid-template-columns: var(--grid-template, repeat(3, minmax(0, 1fr)));
    gap: var(--outer-gap, 20px);
    width: 100%;
}

.how-to-start__step {
    --card-padding: 45px 75px;

    position: relative;
    isolation: isolate;
    display: grid;
    place-items: center;
    width: 100%;
    min-height: 270px;
    padding: var(--card-padding, 45px 75px);
    text-align: center;
    filter: url(../../../assets/images/roundfilter.svg#round);
}

.how-to-start__step::after {
    content: '';
    position: absolute;
    inset: 0;
    z-index: -1;
    display: block;
    background: var(--light-main);
    clip-path: var(--shape, initial);
}

.how-to-start__step--left {
    --shape: polygon(8% 0, 100% 0, 100% 100%, 0 100%);
}

.how-to-start__step--right {
    --shape: polygon(0 0, 100% 0, 92% 100%, 0 100%);
}

.how-to-start__step--both {
    --shape: polygon(8% 0, 100% 0, 92% 100%, 0 100%);
}

.how-to-start__step-title {
    align-self: start;
}

.how-to-start__step-text {
    align-self: center;
    overflow-wrap: break-word;
    text-wrap: pretty;
}

.how-to-start__step-button {
    align-self: end;
    min-width: 70%;
}

@media (max-width: 1199px) {
    .how-to-start {
        --outer-gap: 10px;
    }

    .how-to-start__step {
        --card-padding: 40px;
    }
}

@media (max-width: 899px) {
    .how-to-start {
        --grid-template: (minmax(0, 1fr));
        padding-inline: 50px;
    }

    .how-to-start__step {
        --card-padding: 30px;
    }

    .how-to-start__step::after {
        --shape: initial;
    }
}

@media (max-width: 599px) {
    .how-to-start {
        padding-inline: 0;
    }
}
