.tracks {
    --min-height: 783px;

    position: relative;
    min-height: var(--min-height);
    padding-block: var(--section-padding-block);
}

.tracks::after {
    content: '';
    position: absolute;
    z-index: -1;
    top: -1px;
    width: 100%;
    height: var(--min-height);
    background-color: var(--secondary-main);
    background-image: url('../../../assets/images/tracks/tracks-bg-xl.webp');
    background-repeat: no-repeat;
    background-size: cover;
    clip-path: polygon(0 0, 100% 0, 100% 49%, 0 80%);
}

.tracks__title {
    color: var(--light-main);
}

.tracks__list {
    display: grid;
    gap: 40px 66px;
    justify-items: center;
    align-items: start;
    grid-template-columns: repeat(auto-fit, minmax(385px, 1fr));
    width: 100%;
    padding-block: 20px;
}

@media (max-width: 599px) {
    .tracks__list {
        grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    }
}
