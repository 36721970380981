.competitions {
    padding-block: var(--section-padding-block);
    background-color: var(--secondary-main);
}

.competitions__title {
    margin-block-end: 50px;
    color: var(--light-main);
}

.competitions__ribbon {
    --r: 0.8em;

    width: fit-content;
    padding-inline: calc(var(--r) + 0.3em);
    line-height: 1.8;
    clip-path: polygon(0 0, 100% 0, calc(100% - var(--r)) 50%, 100% 100%, 0 100%, var(--r) 50%);
    background: var(--primary-dark);
}

.competitions__list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(620px, 1fr));
    gap: 60px 40px;
    justify-items: center;
    width: 100%;
    margin-block-end: 50px;
}

.competitions__item-wrapper {
    display: grid;
    width: 100%;
    max-width: 620px;
}

.competitions__item {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    position: relative;
    overflow: initial;
    max-width: 451px;
}

.competitions__item-details {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    z-index: 1;
    justify-self: end;
    width: 300px;
    height: min-content;
    margin-block-start: 30px;
    padding: 10px 35px;
    background-color: var(--light-main);
    border-radius: 4px;
    /* В одном месте есть 8 строк. Для Хрома рекомендация не больше 6, а для ФФ можно и 10. Надеюсь, это небольшая просадка по оптимизации. */
    text-wrap: balance;
    box-shadow: var(--outer-element-shadow);
}

.competitions__item-image {
    display: block;
    max-width: 100%;
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 4px 4px 0 0;
}

.competitions__item-content:last-child {
    padding-bottom: 20px;
}

.competitions__item-title {
    margin: 0;
}

.competitions__message {
    max-width: 575px;
    height: 100%;
    padding-inline: 12px;
    align-self: center;
    font-family: var(--accent-font-family);
    font-weight: 700;
    font-size: 40px;
    background-image: linear-gradient(180deg, var(--light-main) 63%, var(--primary-main) 63%);
    background-size: 100%;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

@media (max-width: 1535px) {
    .competitions__list {
        grid-template-columns: repeat(auto-fit, minmax(550px, 1fr));
    }
}

@media (max-width: 1199px) {
    .competitions__list {
        grid-template-columns: repeat(auto-fit, minmax(406px, 1fr));
    }

    .competitions__item-wrapper {
        grid-template-rows: min-content auto;
        align-items: start;
        max-width: 100%;
    }

    .competitions__item {
        max-width: 100%;
        border-radius: 4px 4px 0 0;
    }

    .competitions__item-details {
        position: relative;
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        z-index: 1;
        justify-self: start;
        max-width: 100%;
        width: 100%;
        height: 100%;
        padding: 10px 35px 30px;
        background-color: var(--light-main);
        box-shadow: none;
        margin: 0;
        border-radius: 0 0 5px 5px;
    }
}

@media (max-width: 899px) {
    .competitions__list {
        grid-template-columns: minmax(0, 1fr);
    }

    .competitions__item-wrapper {
        justify-content: stretch;
    }

    .competitions__item-details {
        text-wrap: initial;
    }

    .competitions__message {
        width: 300px;
        font-size: 20px;
        background-image: linear-gradient(180deg, var(--light-main) 57%, var(--primary-main) 57%);
    }
}

@media (max-width: 599px) {
    .competitions > div {
        justify-content: center;
    }
}
