.main-menu {
    --text-color: currentColor, var(--light-main);

    display: flex;
    justify-content: center;
    column-gap: 30px;
    row-gap: 20px;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
}

.main-menu__item {
    margin: 0;
    padding: 5px;
    text-align: var(--text-align);
}

.main-menu__item::marker {
    color: transparent;
}

.main-menu__link {
    font-family: var(--accent-font-family);
    font-variation-settings: 'wght' 500;
    font-size: var(--header-font-size);
    text-decoration: none;
    color: var(--text-color);
}

.main-menu__link:focus-visible {
    color: var(--primary-main);
    border-bottom: 2px solid var(--primary-main);
    outline-offset: 3px;
}

.main-menu__link:hover {
    color: var(--primary-main);
    border-bottom: 2px solid var(--primary-main);
}

.main-menu__link:active {
    color: var(--primary-dark);
    border-bottom: 2px solid var(--primary-dark);
}

@media (max-width: 1300px) {
    .main-menu {
        column-gap: 20px;
    }
}

@media (max-width: 1199px) {
    .main-menu {
        column-gap: 5px;
    }
}
