.socials {
    --padding-offset: 10px;

    display: flex;
    gap: 10px;
    padding-block: 10px;
    align-items: center;
    margin: 0;
    margin-inline-start: calc(var(--padding-offset) * -1);
    padding: 0;
}

.socials__item::marker {
    color: transparent;
}

.socials__link {
    --filter: initial;

    display: grid;
    place-items: center;
    width: 100%;
    height: 100%;
    padding: var(--padding-offset);
    color: inherit;
}

.socials__icon {
    filter: var(--filter, initial);
}

.socials__link:hover {
    --filter: var(--white-to-primary-main);
}

.socials__link:active {
    --filter: var(--white-to-primary-dark);
}
