.container {
    display: grid;
    max-width: 1536px;
    justify-items: center;
}

.container--initial {
    justify-content: initial;
}

.container--center {
    justify-content: center;
}

.container--start {
    justify-content: start;
}

.container--end {
    justify-content: end;
}

.container--stretch {
    justify-content: stretch;
}
