.change-password {
    --form-width: 500px;

    padding-block: var(--page-top-offset) var(--section-padding-block);
}

.change-password__title {
    margin-block-end: 40px;
    text-align: center;
}

.change-password__form {
    display: grid;
    row-gap: 10px;
    width: var(--form-width);
    margin-block-end: 30px;
}

@media (max-width: 599px) {
    .change-password {
        --form-width: 100%;
    }

    .change-password__title {
        font-size: 3rem;
    }
}
