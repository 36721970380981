.equipment {
    width: 100%;
    padding: 30px;
}

.equipment__list {
    display: grid;
    gap: 40px;
    grid-template-columns: repeat(auto-fit, minmax(376px, 1fr));
}

.equipment__list-title {
    margin-block-end: 10px;
}

.equipment__list-item {
    margin: 0;
    margin-block-end: 5px;
}

@media (max-width: 899px) {
    .equipment {
        width: 90%;
    }

    .equipment__list {
        grid-template-columns: minmax(0, 1fr);
    }
}
