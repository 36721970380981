/* Header */
.main-hero {
    --bottom-decor-height: 31px;

    position: relative;
    display: grid;
    justify-items: center;
    align-items: end;
    min-height: calc(100vh + var(--bottom-decor-height) + 3px);
    padding-block: 100px var(--section-padding-block);
    padding-inline: 15px;
    background-color: var(--secondary-main);
    background-image: url('../../../assets/images/main-hero/main-hero-bg.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center 54%;
    border-image: fill 0 linear-gradient(var(--main-header-overlay), var(--main-header-overlay));
}

.main-hero::after {
    content: '';
    position: absolute;
    bottom: -1px;
    z-index: 1;
    width: 100%;
    height: var(--bottom-decor-height);
    border-radius: var(--bottom-decor-height) var(--bottom-decor-height) 0 0;
    background-color: var(--secondary-main);
}

.main-hero__logo {
    padding-inline: 25px;
    text-align: center;
}

.main-hero__subtitle {
    margin-block-end: 20px;
    text-align: center;
    color: var(--light-main);
}

.main-hero__link {
    margin-inline: auto;
    margin-block-end: 200px;
}

@media (max-width: 599px) {
    .main-hero {
        padding-inline: 10px;
    }

    .main-hero__subtitle {
        font-size: 2rem;
    }
}
