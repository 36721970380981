.workouts {
    padding-block: var(--section-padding-block);
    background-color: var(--secondary-main);
    background-image: url('../../../assets/images/workouts/workouts-bg-xl.webp');
    background-repeat: no-repeat;
    background-size: cover;
    border-image: fill 0 linear-gradient(var(--workouts-overlay), var(--workouts-overlay));
}

.workouts__title {
    color: var(--light-main);
}

.workouts__subtitle {
    color: var(--light-main);
}

.workouts__features-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(500px, 0.5fr));
    gap: 80px;
    justify-content: space-between;
    width: 100%;
    margin-block-end: 80px;
    counter-reset: feature-list;
}

.workouts__feature {
    --marker-width: 140px;

    display: grid;
    grid-template-columns: var(--marker-width) minmax(0, 1fr);
    gap: 15px 20px;
    justify-content: start;
    align-content: start;
}

.workouts__feature-counter {
    position: relative;
    width: var(--marker-width);
    aspect-ratio: 1;
    filter: url('../../../assets/images/roundfilter.svg#round');
}

.workouts__feature-counter::before {
    content: '0' counter(feature-list);
    counter-increment: feature-list;
    position: absolute;
    inset: 0;
    display: grid;
    place-items: center;
    padding: 30px;
    line-height: 1.3;
    font-size: 50px;
    font-family: var(--accent-font-family);
    font-weight: 700;
    color: var(--light-main);
    background-color: var(--primary-main);
    clip-path: polygon(15% 0, 100% 0%, 85% 100%, 0% 100%);
}

.workouts__feature-title {
    max-width: 345px;
    align-self: center;
    margin-block: 0;
    color: var(--light-main);
    font-size: 2.5rem;
    text-align: left;
}

.workouts__feature-subtitle {
    grid-column: span 2;
    max-width: 500px;
    color: var(--light-main);
    text-align: left;
    text-wrap: balance;
}

.workouts__message {
    max-width: 600px;
    height: min-content;
    padding-inline: 12px;
    font-family: var(--accent-font-family);
    font-weight: 700;
    font-size: 40px;
    background-image: linear-gradient(180deg, var(--light-main) 78%, var(--primary-main) 78%);
    background-size: 100%;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

@media (max-width: 1199px) {
    .workouts__features-list {
        grid-template-columns: repeat(auto-fit, minmax(380px, 0.5fr));
        gap: 40px;
        margin-block-end: 50px;
    }

    .workouts__feature {
        --marker-width: 80px;
    }

    .workouts__feature-title {
        font-size: 32px;
    }

    .workouts__feature-subtitle {
        font-size: 20px;
    }

    .workouts__feature-counter::before {
        padding: 15px;
        font-size: 30px;
    }
}

@media (max-width: 899px) {
    .workouts__features-list {
        grid-template-columns: minmax(0, 1fr);
        gap: 60px;
        justify-items: center;
        margin-block-end: 40px;
    }

    .workouts__feature {
        min-width: 500px;
    }

    .workouts__message {
        max-width: 306px;
        font-size: 25px;
        background-image: linear-gradient(180deg, var(--light-main) 82%, var(--primary-main) 82%);
    }
}

@media (max-width: 599px) {
    .workouts__feature {
        min-width: 100%;
        grid-template-columns: minmax(0, 1fr);
    }

    .workouts__feature-title {
        font-size: 25px;
    }

    .workouts__feature-subtitle {
        max-width: 100%;
        grid-column: initial;
        margin-block-end: 48px;
    }

    .workouts__message {
        font-size: 20px;
        background-image: linear-gradient(180deg, var(--light-main) 80%, var(--primary-main) 80%);
    }
}
