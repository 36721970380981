.main-navigation {
    display: grid;
    grid-template-columns: minmax(140px, 1fr) auto minmax(140px, 1fr);
    column-gap: 10px;
    align-items: center;
    width: 100%;
    min-height: 95px;
    padding: 30px 0;
    transition: background-color 0.15s ease-out;
}

.main-navigation__mobile-nav {
    padding-block-start: 24px;
}

.main-navigation__mobile-toolbar {
    display: flex;
    flex-direction: column;
}

.main-navigation__locale-switcher {
    grid-column: 3;
    justify-self: end;
}

.main-navigation__main-menu {
    grid-column: 2;
}

.main-navigation__logo {
    max-width: 150px;
}

.main-navigation__logo--drawer {
    margin-inline-end: 30px;
    margin-block: 30px;
    align-self: end;
}

.main-navigation__burger-button {
    display: none;
    grid-column: 3;
    justify-self: end;
    height: 51px;
    font-size: 1rem;
}

.main-navigation__burger-close {
    margin-inline: auto 15px;
}

.main-navigation__mobile-logo {
    grid-column: 2;
    justify-self: center;
    max-width: 250px;
}

.main-navigation__email {
    max-width: 300px;
    overflow-wrap: break-word;
    margin-inline-end: 30px;
    font-weight: 500;
    text-align: right;
}

.main-navigation__telegram-mobile-link {
    display: flex;
    align-self: end;
    margin-inline-end: 30px;
    color: var(--secondary-main);
}

.main-navigation__telegram-mobile-link:hover {
    color: var(--primary-main);
}

.main-navigation__telegram-mobile-link:active {
    color: var(--primary-dark);
}

@media screen and (max-width: 1199px) {
    .main-navigation {
        grid-template-columns: minmax(40px, 1fr) auto minmax(140px, 1fr);
        column-gap: 5px;
    }
}

@media screen and (max-width: 899px) {
    .main-navigation {
        grid-template-columns: minmax(120px, 1fr) auto minmax(90px, 1fr);
        padding: 20px 0;
    }

    .main-navigation__locale-switcher {
        justify-self: start;
        grid-column: 1;
    }

    .main-navigation__burger-button {
        display: block;
    }
}

@media screen and (max-width: 599px) {
    .main-navigation {
        grid-template-columns: minmax(90px, 1fr) auto minmax(70px, 1fr);
    }
}
