.login {
    --form-width: 500px;
    --flex-direction: row;

    padding-block: var(--page-top-offset) var(--section-padding-block);
}

.login__title {
    margin-block-end: 40px;
    text-align: center;
}

.login__form {
    display: grid;
    row-gap: 10px;
    width: var(--form-width);
    margin-block-end: 20px;
}

.login__options {
    display: grid;
    row-gap: 20px;
    width: var(--form-width);
    margin-block-end: 30px;
}

.login__options-message {
    margin: 0;
    text-align: center;
}

.login__link {
    color: var(--info-dark);
}

.login__link:hover {
    color: var(--primary-main);
}

.login__link:active {
    color: var(--primary-dark);
}

.login__actions {
    display: flex;
    flex-direction: var(--flex-direction);
    row-gap: 20px;
    align-items: center;
    justify-content: space-between;
    width: var(--form-width);
    margin-block-end: 40px;
}

.login__error-wrapper {
    display: grid;
    place-items: center;
    width: 100%;
}

.login__error {
    align-items: center;
    width: var(--form-width);
}

@media (max-width: 599px) {
    .login {
        --form-width: 100%;
        --flex-direction: column;
    }

    .login__title {
        font-size: 3rem;
    }
}
