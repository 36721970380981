.cabinet {
    padding-block: var(--page-top-offset) var(--section-padding-block);
}

.cabinet__title {
    margin-block-end: 60px;
    text-align: center;
}

@media (max-width: 599px) {
    .cabinet__title {
        font-size: 3rem;
    }
}

.cabinet-tabs {
    --section-title-offset: 44px;

    display: grid;
    gap: 20px;
    width: 100%;
}

.cabinet-tabs__section {
    display: grid;
    grid-template-columns: minmax(200px, 0.5fr) minmax(200px, 0.5fr);
    gap: 20px;
    width: 100%;
    margin-block-end: 60px;
}

.cabinet-tabs__title {
    grid-column: span 2;
    margin: 0;
    margin-block-end: 10px;
}

.cabinet-tabs__subtitle {
    margin-block-end: 16px;
}

.cabinet-tabs__text {
    margin: 0;
    text-wrap: balance;
}

.cabinet-tabs__text:not(:last-of-type) {
    margin-block-end: 20px;
}

.cabinet-tabs__forms {
    display: grid;
    justify-content: stretch;
    gap: 50px;
    margin-block-start: calc(var(--section-title-offset) * -1);
}

.cabinet-tabs__forms--no-offset {
    display: grid;
    justify-content: stretch;
    gap: 50px;
    margin-block-start: 50px;
}

.cabinet-tabs__form {
    display: grid;
    row-gap: 10px;
    justify-content: stretch;
    align-content: start;
}

.cabinet-tabs__buttons {
    display: flex;
    align-items: start;
    flex-wrap: wrap;
    gap: 20px;
}

.cabinet-tabs__buttons--offset {
    margin-block-start: var(--section-title-offset);
}

.cabinet-tabs__button {
    justify-self: start;
}

.cabinet-tabs__button--buy {
    min-width: 200px;
}

.cabinet-tabs__activation-message {
    position: relative;
    align-self: start;
    margin-block-end: 40px;
    padding: 25px 30px;
    color: var(--light-main);
    background-color: var(--info-dark);
}

.cabinet-tabs__activation-button {
    display: inline;
    padding: 0;
    background: none;
    font-family: var(--main-font-family);
    font-size: 1.5rem;
    text-decoration: underline;
    text-transform: none;
    color: var(--light-main);
    vertical-align: baseline;
}

.cabinet-tabs__activation-button:hover {
    color: var(--primary-main);
    text-decoration: underline;
}

.cabinet-tabs__support-link {
    color: var(--light-main);
    font-weight: 700;
}

.cabinet-tabs__support-link:hover {
    color: var(--primary-main);
}

.cabinet-tabs__support-link--error:hover {
    color: var(--info-dark);
}

.cabinet-tabs__support-link:active {
    color: var(--primary-dark);
}

.cabinet-tabs__support-link--error:active {
    color: var(--info-main);
}

.cabinet-tabs__activation-close {
    --icon-color: var(--light-main);

    position: absolute;
    top: 5px;
    right: 5px;
}

.cabinet-tabs__activation-close:hover {
    --icon-color: var(--primary-main);

    background-color: var(--light-main);
}

.cabinet-tabs__activation-close--error:hover {
    --icon-color: var(--info-main);
}

.cabinet-tabs__activation-icon {
    color: var(--icon-color);
}

.cabinet-tabs__activation-message--error {
    background-color: var(--error-main);
}

.cabinet-tabs__password-alert {
    align-items: center;
    margin-block-end: 15px;
    font-size: 25px;
}

.cabinet-tabs__password-alert svg {
    width: 25px;
    height: 25px;
}

.cabinet-connections__strava-logo {
    width: 171px;
    height: 58px;
    object-fit: contain;
}

.cabinet-connections__strava-icon {
    width: 193px;
    height: 48px;
}

.cabinet-connections__strava-button {
    width: 193px;
    height: 48px;
    padding: 0;
    margin-inline-start: -4px;
    border-radius: 0;
}

.cabinet-connections__strava-button:hover {
    background-color: none;
}

.cabinet-connections__strava-button:focus-visible {
    outline: 2px solid var(--info-main);
}

@media (max-width: 899px) {
    .cabinet-tabs__text {
        text-wrap: initial;
    }
}

@media (max-width: 599px) {
    .cabinet-tabs__section {
        grid-template-columns: initial;
        gap: 30px;
    }

    .subscription__button {
        justify-self: center;
        min-width: 100%;
    }

    .cabinet-tabs__forms {
        margin-block-start: 0;
    }

    .cabinet-tabs__title {
        grid-column: initial;
    }

    .cabinet-tabs__buttons {
        flex-direction: column;
    }

    .cabinet-tabs__button {
        justify-self: center;
        min-width: 100%;
    }
}
