.cabinet-subscription-plans {
    margin-block-start: 20px;
}

.cabinet-subscription-plans__list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    gap: 20px;
    margin-block-start: 40px;
}

.cabinet-subscription-plans__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 20px;
}

.cabinet-subscription-plans__status {
    display: grid;
    gap: 10px;
    justify-items: start;
    margin-block-start: 20px;
}

@media (max-width: 599px) {
    .cabinet-subscription-plans__list {
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }
}
