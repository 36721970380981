.support {
    padding-block: var(--page-top-offset) var(--section-padding-block);
}

.support__title {
    margin-block-end: 60px;
    text-align: center;
}

.support__list {
    margin-block: 0 40px;
}

.support__question {
    font-weight: 400;
}
