.error-page {
    padding-block: var(--page-top-offset) var(--section-padding-block);
}

.error-page__title {
    margin-block-end: 40px;
}

.error-page__subtitle {
    margin-block-end: 20px;
}
