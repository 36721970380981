.cabinet-logout {
    display: flex;
    gap: 5px;
    align-items: center;
}

.cabinet-logout__user {
    color: var(--light-main);
    font-size: var(--header-font-size);
    font-weight: 700;
}

.cabinet-logout__button:focus-visible {
    background-color: var(--primary-main);
}

.cabinet-logout__icon {
    color: var(--light-main);
}

.cabinet-logout__icon:hover {
    color: var(--primary-main);
}

.cabinet-logout__icon:active {
    color: var(--primary-dark);
}
