.feedback {
    padding-block: var(--section-padding-block);
    background-image: url('../../../assets/images/bg-image3.webp');
    background-size: cover;
    background-position: center;
}

.feedback__form-container {
    width: 60%;
    padding: 40px 80px;
    border-radius: 4px;
    background: var(--light-main);
}

.feedback__form {
    display: grid;
    row-gap: 10px;
    padding-inline: 40px;
}

.feedback__form-subtitle {
    margin-block-end: 20px;
}

.feedback__form-button {
    justify-self: center;
}

.feedback__form__error-message {
    margin: 0;
    color: var(--error-main);
    padding-inline-start: 15px;
    font-size: 0.875rem;
}

@media (max-width: 1199px) {
    .feedback__form-container {
        width: 80%;
    }
}

@media (max-width: 899px) {
    .feedback__form-container {
        width: 100%;
        padding-inline: 40px;
    }

    .feedback__form {
        padding-inline: 0;
    }
}

@media (max-width: 599px) {
    .feedback__form-container {
        padding-inline: 20px;
    }
}
