.locale-switcher {
    display: flex;
    align-items: center;
    gap: 10px;
}

.locale-switcher__select {
    min-width: 100px;
    font-family: var(--accent-font-family);
    font-size: var(--header-font-size);
    font-variation-settings: 'wght' 500;
    border-bottom: 2px solid transparent;
}

.locale-switcher__select:hover {
    color: var(--primary-main);
}

.locale-switcher__item {
    font-family: var(--accent-font-family);
    font-size: var(--header-font-size);
    font-variation-settings: 'wght' 500;
}

@media (max-width: 899px) {
    .locale-switcher__select {
        min-width: 45px;
    }
}
