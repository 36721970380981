.cabinet-main-navigation {
    display: flex;
    column-gap: 40px;
    align-items: center;
    width: 100%;
    min-height: 95px;
    padding: 30px 0;
}

.cabinet-main-navigation__mobile-nav {
    padding-block-start: 24px;
}

.cabinet-main-navigation__mobile-toolbar {
    display: flex;
    flex-direction: column;
}

.cabinet-main-navigation__locale-switcher {
    justify-self: end;
}

.cabinet-main-navigation__main-menu {
    justify-self: start;
}

.cabinet-main-navigation__logo {
    max-width: 150px;
}

.cabinet-main-navigation__logo--drawer {
    margin-inline-end: 30px;
    margin-block: 30px;
    align-self: end;
}

.cabinet-main-navigation__actions {
    display: flex;
    gap: 30px;
    justify-content: end;
    align-items: center;
    margin-inline-start: auto;
}

.cabinet-main-navigation__mobile-logo {
    max-width: 250px;
}

.cabinet-main-navigation__burger-button {
    display: none;
    transform: translateY(2px);
    grid-column: 3;
    grid-row: 1;
    justify-self: end;
    color: var(--light-main);
}

.cabinet-main-navigation__burger-close {
    margin-inline: auto 15px;
}

.cabinet-main-navigation__email {
    max-width: 300px;
    overflow-wrap: break-word;
    margin-inline-end: 30px;
    font-weight: 500;
    text-align: right;
}

.cabinet-main-navigation__telegram-mobile-link {
    display: flex;
    align-self: end;
    margin-inline-end: 30px;
    color: var(--secondary-main);
}

.cabinet-main-navigation__telegram-mobile-link:hover {
    color: var(--primary-main);
}

.cabinet-main-navigation__telegram-mobile-link:active {
    color: var(--primary-dark);
}

@media screen and (max-width: 1199px) {
    .cabinet-main-navigation {
        column-gap: 10px;
    }
}

@media screen and (max-width: 899px) {
    .cabinet-main-navigation {
        display: grid;
        grid-template-columns: minmax(90px, 1fr) auto minmax(90px, 1fr);
        padding: 20px 0;
    }

    .cabinet-main-navigation__burger-button {
        display: block;
    }

    .cabinet-main-navigation__actions {
        display: contents;
    }

    .cabinet-main-navigation__mobile-logo {
        max-width: 250px;
        grid-column: 2;
        grid-row: 1;
    }

    .cabinet-main-navigation__locale-switcher {
        grid-column: 1;
        grid-row: 1;
        justify-self: start;
    }
}
