.subscription {
    --bottom-decor-offset: 200px;

    position: relative;
    isolation: isolate;
    padding-block: var(--section-padding-block) var(--bottom-decor-offset);
    background: url('../../../assets/images/subscription/subscription-bg.webp') no-repeat;
    background-color: var(--light-main);
    background-size: cover;
}

.subscription::before {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    z-index: -1;
    display: block;
    width: 100%;
    height: var(--bottom-decor-offset);
    clip-path: polygon(50% 99%, 100% 0, 100% 100%, 0 100%, 0 0);
    background-color: var(--light-main);
}

.subscription__title {
    margin-block-end: 50px;
    color: var(--light-main);
}

.subscription__plans {
    padding-block: 20px 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
    gap: 40px;
    width: 100%;
}

.subscription__declaration {
    display: grid;
    justify-items: center;
    padding: 30px 45px;
    border-radius: 4px;
}

.subscription__plan-button {
    margin-top: auto;
    text-align: center;
}

.subscription__email {
    color: var(--info-dark);
}

@media (max-width: 1199px) {
    .subscription {
        --bottom-decor-offset: 150px;
    }
}

@media (max-width: 899px) {
    .subscription {
        --bottom-decor-offset: 100px;
    }

    .subscription__plans {
        grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    }
}

@media (max-width: 599px) {
    .subscription {
        --bottom-decor-offset: 50px;
    }

    .subscription__plans {
        grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    }
}
