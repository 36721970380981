.download-options {
    display: grid;
    gap: 30px;
    grid-template-columns: 0.7fr 0.3fr;
}

.download-options__subtitle {
    margin-block-end: 16px;
}

.download-options__requirements {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    margin: 0;
}

.download-options__requirement {
    margin: 0;
    margin-block-end: 10px;
}

.download-options__message {
    margin-block-start: 20px;
}

.download-options__details {
    display: grid;
    justify-content: end;
    align-content: start;
}

.download-options__link {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-block-end: 0;
    transform: translateY(-18px);
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-thickness: 4px;
    text-decoration-color: var(--info-main);
    text-underline-offset: 5px;
}

.download-options__link:hover {
    color: var(--primary-main);
}

.download-options__link:active {
    color: var(--primary-dark);
}

.download-options__icon {
    font-size: 3rem !important;
    transform: translateY(14px);
}

@media (max-width: 899px) {
    .download-options {
        gap: 40px;
        grid-template-columns: 1fr;
    }

    .download-options__requirements {
        display: grid;
        gap: 10px;
        grid-template-columns: 1fr;
        margin: 0;
    }

    .download-options__details {
        display: grid;
        justify-content: center;
        align-content: start;
    }

    .download-options__link {
        font-size: 2.5rem;
    }

    .download-options__icon {
        font-size: 3rem;
    }
}
