.track-card {
    position: relative;
    overflow: initial;
    max-width: 451px;
    border-radius: 5px;
}

.track-card__ribbon {
    position: absolute;
    top: -12px;
    left: -12px;
    width: 150px;
    height: 150px;
    overflow: hidden;
}

.track-card__ribbon::before,
.track-card__ribbon::after {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
    border: 15px solid var(--info-dark);
}

.track-card__ribbon::before {
    top: 0;
    right: 0;
    border-top-color: transparent;
    border-left-color: transparent;
}

.track-card__ribbon::after {
    bottom: 0;
    left: 0;
    border-top-color: transparent;
    border-left-color: transparent;
}

.track-card__ribbon-text {
    position: absolute;
    top: 30px;
    right: -25px;
    display: block;
    width: 225px;
    padding: 9px 0;
    background-color: var(--info-main);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.4);
    color: var(--light-main);
    font-family: var(--accent-font-family);
    text-align: center;
    text-transform: uppercase;
    transform: rotate(-45deg);
}

.track-card__ribbon::after {
    top: 0;
    right: 0;
}

.track-card__image {
    display: block;
    max-width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 4px 4px 0 0;
}

.track-card__details {
    position: absolute;
    top: 99%;
    z-index: 1;
    width: 100%;
    background-color: var(--light-main);
    border-radius: 0 0 5px 5px;
    box-shadow: var(--outer-element-shadow);
    text-wrap: pretty;
}

.track-card__content {
    display: flex;
    justify-content: space-between;
    align-items: start;
    gap: 10px;
}

.track-card__text {
    margin-block-end: 30px;
}

.track-card__content:last-child {
    padding-bottom: 16px;
}

.track-card__title {
    margin: 0;
    line-height: 1.45;
}

@media (max-width: 599px) {
    .track-card__content {
        align-items: center;
    }
}
