.mobile-main-menu {
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 100%;
    height: 100%;
    padding-inline: 30px;
    padding-block: 20px;
    background-color: #ffffff;
}

.mobile-main-menu::before {
    /* content: ''; */
    display: block;
    align-self: end;
    width: 127px;
    height: 24px;
    margin-inline-start: 5px;
    margin-block: 13px 20px;
    background-image: url('../../../assets/images/logo/WattAttackLogoDark.svg');
    background-repeat: no-repeat;
    background-size: contain;
}

.mobile-main-menu__item {
    margin: 0;
    padding: 5px;
    text-align: right;
}

.mobile-main-menu__item::marker {
    color: transparent;
}

.mobile-main-menu__link {
    font-family: var(--accent-font-family);
    font-variation-settings: 'wght' 500;
    font-size: var(--header-font-size);
    text-decoration: none;
    color: var(--text-color);
}

.mobile-main-menu__link--logout {
    padding: 0;
    text-transform: none;
    transform: translateY(-5px);
}

.mobile-main-menu__link--logout svg {
    transform: translateY(1px);
}

.mobile-main-menu__link:hover {
    color: var(--primary-main);
    text-decoration: underline;
    background-color: transparent;
}
