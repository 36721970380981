.google-button {
    border-radius: 4px;
    background-color: #f2f2f2;
}

.google-button:hover {
    box-shadow:
        0px 1px 3px -1px rgba(0, 0, 0, 0.052),
        0px 3px 4px 0px rgba(0, 0, 0, 0.04),
        0px 1px 10px 0px rgba(0, 0, 0, 0.052);
}
