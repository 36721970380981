.download {
    padding-block: var(--section-padding-block);
}

.download__tabs-wrapper {
    width: 100%;
}

.download__tab-panel {
    min-height: 450px;
}

.download__options {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.download__options *[role='tablist'] {
    flex-wrap: wrap;
}

.download__option-name {
    font-size: 1.5rem;
}

@media (max-width: 599px) {
    .download__option-name {
        flex-basis: 50%;
    }
}
