.about {
    padding-block: var(--section-padding-block);
}

.about__title {
    margin-block-end: 50px;
}

.about__content {
    columns: 2;
    column-gap: 80px;
    padding-inline: 20px;
}

.about__text {
    margin: 0;
    margin-block-end: 20px;
}

.about__link {
    color: var(--info-dark);
}

.about__link:hover {
    color: var(--primary-main);
}

.about__link:active {
    color: var(--primary-dark);
}

.about__list {
    margin: 0;
    padding: 0;
    margin-block-end: 30px;
}

.about__list-item {
    list-style-type: ' — ';
}

.about__list-text {
    margin: 0;
    margin-block-end: 5px;
    font-weight: 700;
}

@media (max-width: 1199px) {
    .about__content {
        columns: initial;
        column-gap: initial;
        padding-inline: 20px;
    }

    .breaker {
        display: none;
    }
}
