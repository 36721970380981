.privacy-policy {
    padding-block: var(--page-top-offset) var(--section-padding-block);
    counter-reset: section;
}

.privacy-policy__title {
    margin-block-end: 40px;
}

.privacy-policy__subtitle {
    margin-block-end: 24px;
}

.privacy-policy__section {
    justify-self: stretch;
    margin-block-end: 24px;
}

.privacy-policy__section-title {
    margin-block-end: 16px;
}

.privacy-policy__section-title::before {
    content: counter(section) '. ';
    counter-increment: section;
}

.privacy-policy__text {
    margin-block-end: 8px;
}
