.apple-subscription-plans {
    padding-block: var(--page-top-offset) var(--section-padding-block);
}

.apple-subscription-plans__title {
    margin-block-end: 60px;
    text-align: center;
}

.apple-subscription-plans__list {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    gap: 20px;
    margin-block: 40px 60px;
}

.apple-subscription-plans__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 20px;
}

.apple-subscription-plans__item p:last-of-type {
    margin-bottom: 30px;
}

.apple-subscription-plans__modal {
    width: 70%;
    background-color: white;
}
