.registration {
    --form-width: 500px;
    --flex-direction: row;

    padding-block: var(--page-top-offset) var(--section-padding-block);
}

.registration__title {
    margin-block-end: 40px;
    text-align: center;
}

.registration__alert {
    align-items: center;
}

.registration__form {
    display: grid;
    row-gap: 10px;
    width: var(--form-width);
    margin-block-end: 30px;
}

.registration__paired-fields {
    display: flex;
    gap: 10px;
    justify-content: space-between;
}

.registration__approval {
    display: flex;
    flex-direction: var(--flex-direction);
    align-items: center;
}

.registration__approval label {
    margin-inline-end: 1ch;
}

.registration__link {
    color: var(--info-dark);
}

.registration__link:hover {
    color: var(--primary-main);
}

.registration__link:active {
    color: var(--primary-dark);
}

.registration__actions {
    display: flex;
    flex-direction: var(--flex-direction);
    row-gap: 20px;
    align-items: center;
    justify-content: space-between;
    width: var(--form-width);
}

@media (max-width: 599px) {
    .registration {
        --form-width: 100%;
        --flex-direction: column;
    }

    .registration__title {
        font-size: 3rem;
    }

    .registration__paired-fields {
        display: contents;
    }
}
