/* oswald-latin-wght-normal */
@font-face {
    font-family: 'Oswald Variable';
    font-style: normal;
    font-display: swap;
    font-weight: 200 700;
    src: url(@fontsource-variable/oswald/files/oswald-latin-wght-normal.woff2) format('woff2-variations');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
        U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
        U+FFFD;
}

/* oswald-cyrillic-wght-normal */
@font-face {
    font-family: 'Oswald Variable';
    font-style: normal;
    font-display: swap;
    font-weight: 200 700;
    src: url(@fontsource-variable/oswald/files/oswald-cyrillic-wght-normal.woff2) format('woff2-variations');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* roboto-condensed-latin-wght-normal */
@font-face {
    font-family: 'Roboto Condensed Variable';
    font-style: normal;
    font-display: swap;
    font-weight: 100 900;
    src: url(@fontsource-variable/roboto-condensed/files/roboto-condensed-latin-wght-normal.woff2) format('woff2-variations');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
        U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
        U+FFFD;
}

/* roboto-condensed-cyrillic-wght-normal */
@font-face {
    font-family: 'Roboto Condensed Variable';
    font-style: normal;
    font-display: swap;
    font-weight: 100 900;
    src: url(@fontsource-variable/roboto-condensed/files/roboto-condensed-cyrillic-wght-normal.woff2) format('woff2-variations');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

html {
    scroll-behavior: smooth;
}

html,
body {
    height: 100%;
}

#root {
    display: flex;
    flex-direction: column;
    height: 100%;
}

main {
    flex-grow: 1;
}

:root {
    /* Colors */
    --primary-main: #fd5900;
    --primary-dark: #b13e00;
    --secondary-main: #111f28;
    --light-main: #ffffff;
    --info-main: #0288d1;
    --info-light: #03a9f4;
    --info-dark: #01579b;
    --error-main: #d32f2f;
    --error-light: #ef5350;
    --error-dark: #c62828;
    --vk-color: #0077ff;
    --vk-hover-color: #0071f2;
    --cr-color: #131313;
    --cr-hover-color: #4b4b4b;
    --neutral: #888888;
    --main-header-overlay: #1c1b1b6e;
    --workouts-overlay: #111f28ba;
    --white-to-primary-main: invert(30%) sepia(82%) saturate(6272%) hue-rotate(2deg) brightness(97%) contrast(108%);
    --white-to-primary-dark: invert(60%) sepia(82%) saturate(6272%) hue-rotate(2deg) brightness(97%) contrast(108%);

    /* Paddings */
    --section-padding-block: 50px;
    --page-top-offset: 150px;

    /* Fonts */
    --main-font-family: 'Roboto Condensed Variable', sans-serif;
    --accent-font-family: 'Oswald Variable', sans-serif;
    --header-font-size: 22px;

    /* Shadows */
    --outer-element-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048),
        0 12.5px 10px rgba(0, 0, 0, 0.06), 0 22.3px 17.9px rgba(0, 0, 0, 0.072),
        0 41.8px 33.4px rgba(0, 0, 0, 0.086), 0 100px 80px rgba(0, 0, 0, 0.12);
}

@media (max-width: 1199px) {
    :root {
        --header-font-size: 20px;
    }
}