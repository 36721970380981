.segment-results {
    padding-block: var(--page-top-offset) var(--section-padding-block);
}

.segment-results__title {
    margin-block-end: 60px;
    text-align: center;
}

.segment-results__list {
    margin-block: 0 40px;
}

.segment-results__question {
    font-weight: 400;
}