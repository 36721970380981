.text-field-wrapper {
    display: inline-flex;
    flex-direction: column;
    row-gap: 2px;
}

.text-field-wrapper__helper {
    margin: 0;
    order: 1;
    padding-inline: 15px;
    font-size: 0.875rem;
}

.text-field-wrapper__helper--error {
    color: var(--error-main);
}

.text-field-wrapper__error-slot {
    order: 2;
    min-height: 22px;
}
