.main-footer {
    padding-block: var(--section-padding-block);
    background-color: var(--secondary-main);
}

.main-footer__container {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    justify-items: start;
    align-items: start;
    gap: 20px;
}

.main-footer__copyright {
    display: grid;
    row-gap: 20px;
    justify-self: start;
}

.main-footer__heading {
    margin-block-end: 20px;
    text-align: center;
    font-weight: 700;
}

.main-footer__heading--end {
    text-align: left;
}

.main-footer__requisites {
    max-width: 350px;
    color: var(--light-main);
}

.main-footer__partners {
    justify-self: center;
    grid-column: 2;
    color: var(--light-main);
}

.main-footer__contacts {
    justify-self: end;
    grid-column: 3;
    color: var(--light-main);
}

.main-footer__image {
    display: block;
    max-width: 100%;
    height: auto;
    margin-inline: auto;
    margin-block-end: 40px;
    object-fit: contain;
}

.main-footer__link {
    --filter: initial;

    display: block;
    margin-block-end: 30px;
    color: var(--light-main);
    filter: var(--filter, initial);
}

.main-footer__link:hover {
    --filter: var(--white-to-primary-main);

    text-decoration: underline;
}

.main-footer__link:active {
    --filter: var(--white-to-primary-dark);
}

.main-footer__link--policy {
    justify-self: start;
    font-size: 0.875rem;
}

@media (max-width: 1199px) {
    .main-footer__heading {
        font-size: 30px;
    }
}

@media (max-width: 899px) {
    .main-footer__container {
        grid-template-columns: minmax(0, 1fr);
        align-items: start;
    }

    .main-footer__partners {
        grid-column: initial;
        justify-self: start;
    }

    .main-footer__contacts {
        grid-column: initial;
        justify-self: start;
    }

    .main-footer__heading {
        font-size: 25px;
        text-align: left;
    }
}